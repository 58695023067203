import React from 'react'
//import {Link} from 'gatsby'

import Layout from '../components/layout'
//import Image from '../components/image'
import Hero from '../components/hero'
import growthImage from "../images/Growth.svg"
import modelBW from "../images/ModelBW.jpg"
import openDayIcon from "../images/OpenDayIcon.svg"
import stampRischioZero from "../images/StampRischioZero.svg"
import checklistIcon from '../images/ChecklistIcon.svg';
import './index.scss'

const IndexPage = () => (
  <Layout>
    <div class="landing">
      <Hero/>
      <section class="section rischio-zero">
        <div class="columns has-text-centered">
          <div class="column ">
            <img class="growth-image" src={growthImage} alt="crescita"/>
          </div>
          <div class="column is-three-quarters">
            <h2 class="title is-4">Unico operatore nazionale con Open Day Rischio Zero</h2>
            <a class="button is-medium is-fullwidth is-primary" href="http://www.medextetica.com/contattaci.html">Organizza il tuo Open</a>

            <img class="modelbw" src={modelBW} alt="modella"/>

          </div>
        </div>
      </section>
      <section class="section come has-text-centered">
        <div class="skewed-background"></div>
        <h2 class="title is-3"> Come funziona? </h2>
        <img class="open-day" src={openDayIcon} alt="open day"/>
        <h3 class="title is-4">Organizziamo l'Open Day</h3>
        <p>Organizziamo una giornata al tuo centro dove possiamo dimostrarti la capacità di attrarre fatturato dei nostri dispositivi.</p>
        <p>I tuoi clienti possono effetturare una sessione gratuita senza impegno e se soddisfatti possono acquistare un pacchetto trattamenti con il 50% di sconto.</p>
        <img class="open-day" src={stampRischioZero} alt="rischiozero"/>
        <h3 class="title is-4">0 Rischio, 100% ricavo</h3>
        <p>Dimostrato il potenziale del dispositivo puoi scegliere se noleggiarlo o acquistarlo. Il fatturato chiuso nella giornata resta tutto a te (100%)</p>
        <p>Se il fatturato non copre il prezzo del dispositivo organizziamo un'altro Open Day. Sappiamo cosa serve per vendere pacchetti e ti garantiamo un certo fatturato. Tu non rischi nulla.</p>
        <img class="open-day" src={checklistIcon} alt="rischiozero"/>
        <h3 class="title is-4">In piú offriamo anche</h3>
        <ul class="content">
          <li>Stampa di poster promozionali</li>
          <li>Campagna di promozione su Facebook</li>
          <li>Beauty specialist livello gold che effettuerà i trattamenti</li>
          <li>Formazione sul dispositivo</li>
          <li>Consulenza marketing. Ti aiutiamo a vendere i trattamenti e ad impostare i prezzi giusti per il tuo territorio. In questo modo potrai creare un business permanente di rimodernamento, lifting o depilazione</li>
        </ul>
        <a class="button is-medium is-fullwidth is-primary" href="http://www.medextetica.com/contattaci.html">Organizza il tuo Open</a>
      </section>

    </div>

    {/*
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
    */}
  </Layout>
)

export default IndexPage
