import React from 'react'
import logo from '../images/LogoHorizontal.svg';
import "./hero.scss"
const Hero = () => (
    <header class="hero is-primary is-medium">
        <div class="skewed-background"></div>
        <div class="hero-body">
            <div class="container">
                <img class="main-logo" src={logo} alt="logo"/>

                <h1 className="title">
                    Aumenta il fatturato senza dover investire un euro
                </h1>
                <h2 class="subtitle">
                    Non investi e non rischi niente. Ti chiediamo solo un po' di tempo.
                </h2>
            </div>
        </div>
    </header>
);

export default Hero;